import useApiGatewayUrl from "~/composables/useApiGatewayUrl.js";

export default function useUpgradeDowngrade() {
    const upgradeURL = ref(false);
    const upgradeURLYearly = ref(false);
    const downgradeURL = ref(false);
    const runtimeConfig = useRuntimeConfig();
    const { apiGatewayUrl } = useApiGatewayUrl();

    onMounted(() => {
        upgradeURL.value = `${runtimeConfig.public.auth0.domain}/authorize?response_type=code&client_id=${runtimeConfig.public.auth0.clientID}&redirect_uri=${apiGatewayUrl.value}/auth-callback&scope=openid%20profile%20email&state=${window.location.origin+'/login/?intent=upgrade||'+apiGatewayUrl.value+'/auth-callback'}`;

        upgradeURLYearly.value = `${runtimeConfig.public.auth0.domain}/authorize?response_type=code&client_id=${runtimeConfig.public.auth0.clientID}&redirect_uri=${apiGatewayUrl.value}/auth-callback&scope=openid%20profile%20email&state=${window.location.origin+'/login/?intent=upgrade-yearly||'+apiGatewayUrl.value+'/auth-callback'}`;
    });

    return { upgradeURL, upgradeURLYearly };
}
